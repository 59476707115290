import { Breadcrumb, Button, Card, Form, Icon, Input, message } from "antd"
import { gql } from "apollo-boost"
import { Link } from "gatsby"
import qs from "query-string"
import React from "react"
import { Mutation, Query } from "react-apollo"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const formItemLayoutWithLabel = {
  labelCol: { xs: { span: 24 }, sm: { span: 8 } },
  wrapperCol: { xs: { span: 24 }, sm: { span: 16 } },
}

const formItemLayoutWithoutLabel = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 16, offset: 8 },
  },
}

const UPDATE_CUSTOMER = gql`
  mutation updateCustomer($id: Int, $changes: mbt_customers_set_input) {
    update_mbt_customers(where: { id: { _eq: $id } }, _set: $changes) {
      returning {
        name
        address
        email
        phone_number
        fax
      }
    }
  }
`

const CustomerForm = Form.create({
  mapPropsToFields: ({ customer = {} }) => ({
    name: Form.createFormField({ value: customer.name }),
    address: Form.createFormField({ value: customer.address }),
    email: Form.createFormField({ value: customer.email }),
    phone_number: Form.createFormField({ value: customer.phone_number }),
    fax: Form.createFormField({ value: customer.fax }),
  }),
})(({ customer, form, loading: queryLoading }) => (
  <Mutation mutation={UPDATE_CUSTOMER}>
    {(updateCustomers, { loading }) => {
      const disabled = queryLoading || loading
      return (
        <Form
          {...formItemLayoutWithLabel}
          onSubmit={e => {
            e.preventDefault()
            form.validateFieldsAndScroll(async (errors, changes) => {
              if (!errors) {
                try {
                  await updateCustomers({
                    variables: { id: customer.id, changes },
                  })
                  form.setFieldsInitialValue(changes)
                  message.success("Successfully edited customer")
                } catch (err) {
                  message.error("Failed to edit customer")
                }
              }
            })
          }}
        >
          <Form.Item label="Name">
            {form.getFieldDecorator("name", {
              rules: [
                {
                  required: true,
                  message: "Name is required",
                  whitespace: true,
                },
              ],
            })(<Input disabled={disabled} />)}
          </Form.Item>
          <Form.Item label="Address">
            {form.getFieldDecorator("address", {
              rules: [],
            })(<Input.TextArea disabled={disabled} />)}
          </Form.Item>
          <Form.Item label="Email">
            {form.getFieldDecorator("email", {
              rules: [],
            })(<Input disabled={disabled} />)}
          </Form.Item>
          <Form.Item label="Phone number">
            {form.getFieldDecorator("phone_number", {
              rules: [],
            })(<Input disabled={disabled} />)}
          </Form.Item>
          <Form.Item label="Fax">
            {form.getFieldDecorator("fax", {
              rules: [],
            })(<Input disabled={disabled} />)}
          </Form.Item>
          <Form.Item {...formItemLayoutWithoutLabel}>
            <Button
              type="primary"
              disabled={!form.isFieldsTouched()}
              htmlType="submit"
              loading={loading}
            >
              Save
            </Button>
          </Form.Item>
        </Form>
      )
    }}
  </Mutation>
))

const GET_CUSTOMER = gql`
  query getCustomer($id: Int!) {
    mbt_customers_by_pk(id: $id) {
      id
      name
      address
      email
      phone_number
      fax
    }
  }
`

export default ({ location }) => {
  const { id } = qs.parse(location.search)
  return (
    <Layout>
      <SEO title="Customer" />
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>
          <Link to="/">
            <Icon type="home" />
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/customer/">Customer</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Edit</Breadcrumb.Item>
      </Breadcrumb>
      <Card>
        <Query
          fetchPolicy="cache-and-network"
          query={GET_CUSTOMER}
          variables={{ id }}
        >
          {({ data, loading }) => (
            <CustomerForm
              customer={data.mbt_customers_by_pk}
              loading={loading}
            />
          )}
        </Query>
      </Card>
    </Layout>
  )
}
